import styled from 'styled-components';
import {media, typography, colors} from '~src/theme';
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';
import SocialMediaWidget from '~features/SocialMediaWidget';
import {ButtonLink} from '~src/theme/buttons';

export const Container = styled.section`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: all 0.3s ease;
  color: ${colors.WHITE};
`;

export const BackgroundImageWrapper = styled(BackgroundImage)`
  height: calc(100% - 60px);
`;

export const GridContainer = styled.div`
  position: relative;
  overflow: hidden;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: minmax(100px, auto) 1fr;
  grid-template-areas:
    'LogoPanel InfoPanel'
    'ContentPanel ContentPanel'
    'ImagePanel ImagePanel'
    'HeroFiller HeroFiller';
  width: 100%;

  ${media.sevens`
    grid-template-columns: 40% 60%;
    grid-template-rows: minmax(100px, auto) 1fr;
    grid-template-areas:
      'LogoPanel InfoPanel'
      'ContentPanel ContentPanel'
      'ImagePanel ImagePanel'
      'HeroFiller HeroFiller';
  `}

  ${media.tens`
    grid-template-columns: 50% 50%;
    grid-template-rows: 130px 1fr;
    grid-template-areas:
      'LogoPanel InfoPanel'
      'ImagePanel ContentPanel'
      'ImagePanel HeroFiller';
  `};
`;

export const LogoPanel = styled.div`
  grid-area: LogoPanel;
  z-index: 1;
  padding: 30px 20px;

  ${media.sevens`
    padding: 0;
  `}
`;

export const Logo = styled(Img)`
  position: relative;
  display: grid;
  justify-self: center;
  align-items: center;
  max-width: 160px;
  max-height: 75px;
  height: 100%;
  width: 100%;

  ${media.sevens`
    position: absolute;
    top: 30%;
    left: 5%;
    max-width: 240px;
    max-height: 100px;
    height: auto;
    width: auto;
  `}

  ${media.tens`
    top: 33%;
    left: 5%;
    max-width: 365px;
    max-height: 200px;
  `}
`;

export const ImagePanel = styled.div`
  grid-area: ImagePanel;
  padding: 0 10px;
  margin-bottom: -35px;

  ${media.sevens`
    padding: 0 20px;
    margin-bottom: -60px;
  `}

  ${media.tens`
    padding: 0;
    margin-bottom: 0;
  `}
`;

export const Image = styled(Img)`
  ${media.sevens`
    min-height: 700px;
  `}
`;

export const InfoPanel = styled.div`
  grid-area: InfoPanel;
  display: grid;
  justify-self: end;
  align-items: center;
  padding: 0 10px;

  ${media.sevens`
    grid-gap: 20px;
    grid-template-columns: auto auto;
    justify-self: end;
    align-self: end;
    height: 65px;
  `};

  ${media.tens`
    grid-gap: 40px;
    grid-template-columns: auto auto auto;
    justify-self: center;
    align-self: end;
  `}
`;

export const ContentPanel = styled.div`
  grid-area: ContentPanel;
  padding: 20px;

  ${media.sevens`
    padding: 75px 25px 25px 25px;
  `}

  ${media.tens`
    padding: 0 40px;
    margin: auto auto;
  `}
`;

export const Title = styled(typography.h1)`
  margin: 0 0 5px 0;
  border-bottom: solid 2.5px ${colors.YELLOW};
  padding-bottom: 8px;
  display: inline-block;
  font-size: 65px;
  line-height: 40px;
  max-width: 255px;

  ${media.sevens`
    max-width: none;
    margin: 0 0 15px 0;
    font-size: 80px;
    line-height: 65px;
  `}

  ${media.twelves`
    margin: 0 0 25px 0;
    font-size: 130px;
    line-height: 97px;
    border-bottom: solid 5px ${colors.YELLOW};
  `}
`;

export const SecondaryTitle = styled(typography.h2)`
  margin: 0;
  border-bottom: solid 2.5px ${colors.YELLOW};
  display: inline-block;
  font-size: 41px;
  line-height: 45px;

  ${media.sevens`
    font-size: 45px;
    line-height: 55px;
  `}

  ${media.twelves`
    font-size: 80px;
    line-height: 80px;
    border-bottom: solid 5px ${colors.YELLOW};
  `}
`;

export const Copy = styled(typography.copy)`
  max-width: 95%;
  padding: 10px 0;

  ${media.tens`
    max-width: 525px;
  `}
`;

export const CustomSocialMediaWidget = styled(SocialMediaWidget)`
  display: none;

  ${media.sevens`
    display: grid;
  `}
`;

export const PhoneNumber = styled.a`
  ${typography.groteskMedium}

  font-size: 21px;
  font-weight: 500;
  letter-spacing: 0.66px;
  line-height: 26px;
  padding: 0 25px 0 0;
  text-decoration: none;
  color: ${colors.WHITE};
  transition: all 0.3s ease;

  &:hover {
    color: ${colors.YELLOW};
  }

  ${media.sevens`
    padding: 9px 25px 0 0;
  `}

  ${media.tens`
    padding: 17px 0;
  `}
`;

export const ButtonWrapper = styled.div`
  display: none;

  ${media.tens`
    display: block;
  `}
`;

export const InfoButton = styled(ButtonLink)`
  padding: 10px;

  ${media.twelves`
    padding: 40px 22px;
  `}
`;

export const ContentButtonWrapper = styled.div`
  display: block;
  margin-bottom: 10px;

  ${media.tens`
    display: none;
  `}
`;

export const ContentButton = styled(ButtonLink)``;

export const HeroFiller = styled.div`
  grid-area: HeroFiller;
  align-self: end;
  width: 100%;
  height: 75px;
  display: block;
  background: ${colors.WHITE};
`;
