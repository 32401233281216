import styled, {css} from 'styled-components';
import {typography, media, colors} from '~src/theme';

export const Container = styled.section`
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 0 20px 0;

  ${media.sevens`
    padding: 50px 0 75px 0;
  `}

  ${media.tens`
    padding: 100px 0 140px 0;
  `}
`;

export const Title = styled(typography.h3)`
  text-align: center;
  text-transform: uppercase;
  color: ${colors.PRUSSIAN_BLUE};
  font-size: 21px;
  letter-spacing: 1px;
  line-height: 25px;

  ${media.sevens`
    margin-bottom: 35px;
    font-size: 28px;
    letter-spacing: 1px;
    line-height: 33px;
  `}

  ${media.tens`
    margin-bottom: 75px;
  `}
`;

export const ServiceGrid = styled.div`
  display: grid;
  justify-content: center;
  align-content: center;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 20px;
  margin: 50px 20px 10px 20px;

  ${media.fives`
    grid-column-gap: 30px;
    grid-row-gap: 40px;
  `}

  ${media.sevens`
    margin: 0 auto;
    max-width: 1000px;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(2, minmax(200px, auto));
    grid-gap: 80px;
    padding: 40px;
  `}

  ${media.tens`
    padding: 0;
  `}
`;

export const ServiceGridItem = styled.div`
  justify-self: center;
`;

export const ItemTitle = styled(typography.h3)`
  font-size: 17px;
  line-height: 19px;
  letter-spacing: -0.5px;
  text-align: center;
  color: ${colors.DARK_GRAY};
  max-width: 165px;

  ${media.sevens`
    font-size: 23px;
    line-height: 28px;
    letter-spacing: -0.5px;
    max-width: none;
  `}

  ${media.tens`
    font-size: 28px;
    line-height: 31px;
    letter-spacing: -0.5px;
  `}
`;

export const IconWrapper = styled.div<{id: string}>`
  min-height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${media.sevens`
    min-height: 115px;
  `}

  svg {
    margin: auto auto;

    ${(id) => overrideMobileDimensions(id)};

    ${media.sevens`
      ${(id) => overrideDesktopDimensions(id)};
    `}
  }
`;

export const overrideDesktopDimensions = ({id}: {id: string}) => {
  switch (id) {
    case '2':
      return css`
        width: 105px;
        height: 110px;
      `;
      break;
    case '3':
    case '4':
      return css`
        width: 140px;
        height: 80px;
      `;
      break;
    case '5':
      return css`
        width: 150px;
        height: 114px;
      `;
      break;
    case '6':
      return css`
        width: 95px;
        height: 92px;
      `;
      break;
    case '7':
      return css`
        width: 140px;
        height: 110px;
      `;
      break;
    default:
      return css`
        width: auto;
        height: auto;
      `;
      break;
  }
};

export const overrideMobileDimensions = ({id}: {id: string}) => {
  switch (id) {
    case '2':
      return css`
        width: 65px;
        height: 65px;
      `;
      break;
    case '3':
      return css`
        width: 115px;
        height: 65px;
      `;
      break;
    case '4':
      return css`
        width: 85px;
        height: 48px;
      `;
      break;
    case '5':
      return css`
        width: 80px;
        height: 60px;
      `;
      break;
    case '6':
      return css`
        width: 70px;
        height: 68px;
      `;
      break;
    case '7':
      return css`
        width: 85px;
        height: 65px;
      `;
      break;
    default:
      return css`
        width: auto;
        height: auto;
      `;
      break;
  }
};
