import React, {useState} from 'react';
import {
  ServicePackageSectionProps,
  ServicePackageNodes,
  ServicePackagePerkSets,
  ServicePackagePerks,
} from '~src/types';
import {
  Container,
  Wrapper,
  ServicePackageList,
  ServicePackageListHeading,
  ServicePackageListItem,
  PerkGridContainer,
  MobilePerks,
  PerkSet,
  PerkSetTitle,
  Perk,
  PerkDescription,
  BlueFeathersImage,
} from './styles';

const ServicePackageSection = ({
  heading,
  servicePackages,
  image,
}: ServicePackageSectionProps): JSX.Element => {
  const packages = servicePackages.map((pkg: any) => pkg.name);
  const [selectedPackage, setSelectedPackage] = useState(servicePackages[0]);

  const togglePerks = (packageName: string, servicePackages: any) => {
    for (let pkg of servicePackages) {
      if (packageName.includes(pkg.name)) {
        setSelectedPackage(pkg);
      }
    }
  };

  return (
    <Container>
      <Wrapper>
        <ServicePackageList>
          <ServicePackageListHeading>
            {heading.headline}
          </ServicePackageListHeading>
          {packages.map((pkg: any, index: number) => {
            return (
              <React.Fragment key={index}>
                <ServicePackageListItem>
                  <span
                    onClick={(e: React.MouseEvent) => {
                      e.preventDefault();
                      const packageName = e?.currentTarget?.innerHTML;
                      togglePerks(packageName, servicePackages);
                    }}
                    className={pkg === selectedPackage.name ? 'active' : ''}
                  >
                    {pkg}
                    <span id="symbol">
                      {pkg === selectedPackage.name ? '-' : '+'}
                    </span>
                  </span>
                </ServicePackageListItem>
                <MobilePerks
                  className={pkg === selectedPackage.name ? 'active' : ''}
                >
                  {selectedPackage.perkSets.map(
                    (perkSet: ServicePackagePerkSets, index: number) => {
                      return (
                        <PerkSet key={index}>
                          <PerkSetTitle showPerkSet={perkSet.showPerkSet}>
                            {perkSet.name}
                          </PerkSetTitle>
                          {perkSet.perks.map(
                            (prk: ServicePackagePerks, index: number) => {
                              return (
                                <React.Fragment key={index}>
                                  <Perk showPerk={prk.showPerk}>
                                    {prk.name}
                                    {prk.description && (
                                      <PerkDescription showPerk={prk.showPerk}>
                                        {prk.description}
                                      </PerkDescription>
                                    )}
                                  </Perk>
                                </React.Fragment>
                              );
                            },
                          )}
                        </PerkSet>
                      );
                    },
                  )}
                </MobilePerks>
              </React.Fragment>
            );
          })}
        </ServicePackageList>
        <PerkGridContainer>
          {selectedPackage.perkSets.map(
            (perkSet: ServicePackagePerkSets, index: number) => {
              return (
                <PerkSet key={index}>
                  <PerkSetTitle showPerkSet={perkSet.showPerkSet}>
                    {perkSet.name}
                  </PerkSetTitle>
                  {perkSet.perks.map(
                    (prk: ServicePackagePerks, index: number) => {
                      return (
                        <React.Fragment key={index}>
                          <Perk showPerk={prk.showPerk}>
                            {prk.name}
                            {prk.description && (
                              <PerkDescription showPerk={prk.showPerk}>
                                {prk.description}
                              </PerkDescription>
                            )}
                          </Perk>
                        </React.Fragment>
                      );
                    },
                  )}
                </PerkSet>
              );
            },
          )}
        </PerkGridContainer>
      </Wrapper>
      <BlueFeathersImage fluid={image.childImageSharp.fluid} />
    </Container>
  );
};
export default ServicePackageSection;
