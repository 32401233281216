import React, {useState, FormEvent} from 'react';
import styled from 'styled-components';
import TextInput from '~features/Form/TextInput';
import submitForm from '~features/Form/utils/submitForm';
import {media, typography, colors} from '~src/theme';

const TAGS = ['contact-submission'];

const MailchimpNetlifyForm = (): JSX.Element => {
  const [successSubmission, setSuccessSubmission] = useState(false);
  const [formData, updateFormData] = useState({
    NAME: '', // Separate first and last or together?
    EMAIL: '',
    PHONE: '',
    UNIVERSITY: '',
    YEAR: '',
  });
  const [errors, setErrors] = useState({
    NAME: '',
    EMAIL: '',
    PHONE: '',
  });

  const handleChange = (event: Event): void => {
    const {name, value} = event.target as HTMLInputElement;
    switch (name) {
      case 'EMAIL':
        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        // return re.test(String(email).toLowerCase());
        const emailError =
          value.length > 0 && emailRegex.test(String(value).toLowerCase())
            ? ''
            : 'Please enter a valid email address';
        setErrors({
          ...errors,
          EMAIL: `${emailError}`,
        });
        updateFormData({
          ...formData,
          [name]: value,
        });
        break;
      case 'PHONE':
        value.length === 0 &&
          setErrors({
            ...errors,
            PHONE: '',
          });
        const phoneNumberRegex = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})?([ .-]?)([0-9]{4})/;
        const phoneNumberDigits = value.replace(/\D/g, '');
        if (value.length > 0) {
          if (phoneNumberRegex.test(value) && phoneNumberDigits.length === 10) {
            setErrors({
              ...errors,
              PHONE: '',
            });
          } else {
            setErrors({
              ...errors,
              PHONE: 'Please enter a valid phone number',
            });
          }
        }
        value.length < 15 &&
          updateFormData({
            ...formData,
            [name]: value,
          });
        break;
      case 'NAME':
        const nameError = value.length === 0 ? 'Please enter your name' : '';
        setErrors({
          ...errors,
          NAME: `${nameError}`,
        });
        updateFormData({
          ...formData,
          [name]: value,
        });
        break;
      case 'UNIVERSITY':
      case 'YEAR':
        updateFormData({
          ...formData,
          [name]: value,
        });
        break;
      default:
        break;
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    const valid = Object.values(errors).every((x) => x === null || x === '');
    if (valid && formData.EMAIL.length > 0 && formData.NAME.length > 0) {
      submitForm({
        formElement: event.target,
        formData,
        tags: TAGS,
        successCallback: setSuccessSubmission,
      });
    } else {
      const nameError = formData.NAME === '' ? 'Please enter your name' : '';
      const emailError =
        formData.EMAIL === '' ? 'Please enter a valid email address' : '';
      setErrors({
        ...errors,
        NAME: `${nameError}`,
        EMAIL: `${emailError}`,
      });
    }
  };

  const {NAME, EMAIL, PHONE, UNIVERSITY, YEAR} = formData;
  return (
    <div>
      {successSubmission ? (
        <ThankYouWrapper>
          <ThankYouHeader>Thank You!</ThankYouHeader>
          <ThankYouCopy>
            We appreciate your interest in BlueOwl. We’ll get back to you
            shortly.
          </ThankYouCopy>
        </ThankYouWrapper>
      ) : (
        <div>
          <form
            onSubmit={handleSubmit}
            name="SignUp"
            data-netlify="true"
            netlify-honeypot="bot-field"
          >
            <Field>
              <Label htmlFor="NAME">What is your name?*</Label>
              <TextInput
                type="text"
                name="NAME"
                value={NAME}
                onChange={handleChange}
                ariaLabel="Name"
                placeholder="ex. Jane Smith"
                error={errors.NAME}
              />
              <Error>{errors.NAME}</Error>
            </Field>
            <Field>
              <Label htmlFor="EMAIL">What is your email?*</Label>
              <TextInput
                type="email"
                name="EMAIL"
                value={EMAIL}
                onChange={handleChange}
                error={errors.EMAIL}
                ariaLabel="Email"
                placeholder="ex. janesmith@gmail.com"
              />
              <Error>{errors.EMAIL}</Error>
            </Field>
            <Field>
              <Label htmlFor="PHONE">What is your phone number?</Label>
              <TextInput
                type="tel"
                name="PHONE"
                value={PHONE}
                ariaLabel="Phone"
                onChange={handleChange}
                pattern="^(?:\(\d{3}\)|\d{3})[- . ]?\d{3}[- . ]?\d{4}$"
                placeholder="ex. 333.444.5555"
                error={errors.PHONE}
              />
              {errors.PHONE.length > 0 && <Error>{errors.PHONE}</Error>}
            </Field>
            <Field>
              <Label htmlFor="UNIVERSITY">
                What is your/the student’s affiliated university/college?
              </Label>
              <TextInput
                type="text"
                name="UNIVERSITY"
                value={UNIVERSITY}
                ariaLabel="University"
                onChange={handleChange}
                placeholder="ex. Boston College"
              />
            </Field>
            <Field>
              <Label htmlFor="YEAR">
                What year of school are you/your student in?
              </Label>
              <TextInput
                type="text"
                name="YEAR"
                value={YEAR}
                ariaLabel="School Year"
                onChange={handleChange}
                placeholder="ex. Junior, Senior, Recent Grad"
              />
            </Field>
            <FormAction>
              <RequiredText>*required</RequiredText>
              <SubmitButton type="submit">SUBMIT</SubmitButton>
            </FormAction>
          </form>
        </div>
      )}
    </div>
  );
};

export default MailchimpNetlifyForm;

const ThankYouWrapper = styled.div`
  position: relative;
  margin-bottom: 100px;

  ${media.sevens`
    margin-bottom: 200px;
  `};
`;

const ThankYouHeader = styled.div`
  ${typography.groteskMedium};

  color: ${colors.DARK_BLUE};
  font-size: 30px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 0.9em;
  margin: 0 0 20px;
`;

const ThankYouCopy = styled.div`
  ${typography.groteskRegular};

  color: ${colors.DARK_BLUE};
  font-size: 16px;
  line-height: 2em;
  max-width: 90%;
`;

const Field = styled.div`
  display: block;
  margin: 0 0 25px;
`;

const Label = styled.label`
  ${typography.groteskMedium};
  color: ${colors.DARK_BLUE};
  font-size: 19px;
  line-height: 21px;
  letter-spacing: 0.4px;
`;

const SubmitButton = styled.button`
  ${typography.groteskMedium}

  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: no-wrap;
  color: ${colors.PRUSSIAN_BLUE};
  text-decoration: none;
  height: 60px;
  max-height: 60px;
  border: 3px solid ${colors.YELLOW};
  border-radius: 32px;
  background-color: ${colors.YELLOW};
  padding: 19px 37px;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  text-transform: uppercase;
  width: 190px;
  cursor: pointer;

  &:hover {
    background-color: ${colors.CONGRESS_BLUE};
    border: 3px solid ${colors.CONGRESS_BLUE};
    color: ${colors.WHITE};
  }
`;

const FormAction = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 5px;

  ${media.sevens`
    padding-top: 20px;
  `}
`;

const RequiredText = styled.div`
  ${typography.groteskMedium};

  display: flex;
  flex: 0 0 auto;
  align-items: center;
  color: ${colors.DARK_BLUE};
  font-size: 19px;
  line-height: 21px;
`;

const Error = styled.div`
  ${typography.groteskRegular};

  font-weight: 15px;
  line-height: 18px;
  color: red;
  margin-top: 5px;
`;
