import React, {useRef} from 'react';
import Slider, {Settings} from 'react-slick';
import {
  Container,
  CarouselBackgroundImageWrapper,
  Title,
  CardWrapper,
  Slick,
  CarouselHeading,
  ButtonWrapper,
} from './styles';
import Card from './card';
import {media, typography, colors, breakpoints} from '~src/theme';
import {TestimonialCarouselProps, TestimonialCarouselCardProps} from '@types';
import CarouselButtons from './carousel-buttons';

const cfg = {
  maxCount: 4.5,
  breaks: [
    {px: breakpoints.sixteens, count: 3.5},
    {px: breakpoints.twelves, count: 3},
    {px: breakpoints.tens, count: 2.5},
    {px: breakpoints.nines, count: 2},
    {px: breakpoints.fives, count: 1.5},
    {px: breakpoints.fours, count: 1.2},
  ],
};

const Noop = () => null;

const slickProps = (slideCount: number) => ({
  prevArrow: <Noop />,
  nextArrow: <Noop />,
  easing: 'ease-in-out',
  speed: 500,
  slidesToScroll: 1,
  infinite: true,
  slidesToShow: Math.min(slideCount, cfg.maxCount),
  responsive: cfg.breaks.map((brk) => ({
    breakpoint: brk.px,
    settings: {
      infinite: true,
      slidesToShow: Math.min(slideCount, brk.count),
    },
  })),
});

const CarouselSection = ({
  heading,
  cards,
  backgroundImage,
}: TestimonialCarouselProps): JSX.Element => {
  const ref: any = useRef();

  const onPrev = () => {
    if (ref.current && ref.current.slickPrev) {
      ref.current.slickPrev();
    }
  };

  const onNext = () => {
    if (ref.current && ref.current.slickNext) {
      ref.current.slickNext();
    }
  };

  return (
    <Container>
      <CarouselBackgroundImageWrapper
        fluid={backgroundImage.childImageSharp.fluid}
      >
        <CarouselHeading>
          <Title as={typography.h2}>{heading.headline}</Title>
          <ButtonWrapper>
            <CarouselButtons {...{onPrev, onNext}} />
          </ButtonWrapper>
        </CarouselHeading>
        <CardWrapper>
          <Slick
            as={Slider}
            ref={ref}
            cfg={cfg}
            slideCount={cards.length}
            {...slickProps(cards.length)}
          >
            {cards.map((card: any) => {
              return <Card key={`Carousel-card-${card.id}`} {...card} />;
            })}
          </Slick>
        </CardWrapper>
      </CarouselBackgroundImageWrapper>
    </Container>
  );
};

export default CarouselSection;
