import React from 'react';
import styled from 'styled-components';
import {colors, typography, media} from '~src/theme';

const StyledButton = styled.button`
  ${typography.groteskMedium}

  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: no-wrap;
  color: ${colors.PRUSSIAN_BLUE};
  text-decoration: none;
  height: 60px;
  max-height: 60px;
  border: 3px solid ${colors.YELLOW};
  border-radius: 32px;
  background-color: ${colors.YELLOW};
  padding: 19px 37px;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  text-transform: uppercase;

  &:hover {
    background-color: ${colors.CONGRESS_BLUE};
    border: 3px solid ${colors.CONGRESS_BLUE};
    color: ${colors.WHITE};
  }
`;

export const ButtonLink = (props: any) => {
  return (
    <StyledButton
      as="a"
      className={`${props.extraClasses} ${props.className || ''}`}
      href={props.href || ''}
      target={props.openInNewWindow ? '_blank' : '_self'}
      data-analytics-id={props.trackingID}
    >
      {props.buttonText}
    </StyledButton>
  );
};

// ButtonLink.propTypes = {
//   href: PropTypes.string.isRequired,
//   buttonText: PropTypes.string.isRequired,
//   openInNewWindow: PropTypes.bool,
//   color: PropTypes.oneOf([
//     'blue',
//     'green',
//     'gray',
//     'red',
//     'yellow',
//     'charcoal',
//     'lightGray',
//     'white',
//   ]),
//   extraClasses: PropTypes.string,
//   trackingID: PropTypes.string,
//   file: PropTypes.object,
// };

// ButtonLink.defaultProps = {
//   color: 'green',
//   extraClasses: '',
// };
