import styled from 'styled-components';
import {media, typography, colors} from '~src/theme';
import BackgroundImage from 'gatsby-background-image';

export const Container = styled.section`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: all 0.3s ease;
  color: ${colors.DARK_BLUE};
`;

export const CarouselBackgroundImageWrapper = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
  padding: 35px 0 0 10px;

  ${media.sevens`
    min-height: 525px;
    padding: 35px 0 35px 35px;
  `}

  ${media.tens`
    padding: 70px 0 100px 70px;
  `}

  ${media.twelves`
    padding: 70px 0 100px 120px;
    min-height: 750px;
  `}
`;

export const CarouselHeading = styled.div`
  display: grid;

  ${media.sevens`
    grid-template-columns: auto 120px;
  `}

  ${media.twelves`
    grid-template-columns: auto 240px;
  `}

  ${media.fourteens`
    grid-template-columns: auto 360px;
  `}
`;

export const ButtonWrapper = styled.div``;

export const Title = styled(typography.h3)`
  max-width: 240px;
  margin: 0;

  ${media.fours`
    max-width: none;
  `}
`;

export const CardWrapper = styled.div`
  padding: 50px 0;
  z-index: 0;
  position: relative;

  ${media.sevens`
    padding: 0;
  `}
`;

export const Slick = styled.div<{cfg: any; slideCount: number}>`
  position: relative;

  ${media.sevens`
    padding: 65px 0 35px 0; /* overflow-x hidden still hides top */
    // overflow-x: hidden;
  `};

  .slick-list {
    overflow: visible;
    margin: 0 -8px;
  }

  .slick-track {
    display: flex;
    align-items: stretch;
    flex-wrap: nowrap;
    z-index: 4;
    margin-left: 16vw;

    ${media.fours`
      margin-left: 32.5vw;
    `}

    ${media.fives`
      margin-left: 0;
    `}

    ${media.nines`
      margin-left: 19.5vw;
    `}

    ${media.tens`
      margin-left: 0.5vw;
    `}

    ${media.twelves`
      margin-left: 13.5vw;
    `}

    ${media.fourteens`
      margin-left: 13.5vw;
    `}

    ${media.sixteens`
      margin-left: 10.5vw;
    `}

    div {
      outline: none;
    }
  }

  .slick-slide {
    display: flex;
    align-items: stretch;
    width: calc(100% - 10px);

    ${media.fives`
      width: auto;
    `}
  }

  .slick-slide > div {
    display: flex;
    align-items: stretch;
    flex: 0 0 100%;
    margin: 0 8px;
  }
`;
