export default {
  DARK_BLUE: '#172c56',
  PRUSSIAN_BLUE: '#002758',
  YELLOW: '#fffb00',
  WHITE: '#ffffff',
  LIGHT_GRAY: '#f4f4f4',
  DARK_GRAY: '#797c82',
  CONGRESS_BLUE: '#014E99',
  RED: '#FF0000',
};
