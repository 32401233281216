import React from 'react';
import styled from 'styled-components';
import {media, typography, colors} from '~src/theme';
import Img from 'gatsby-image';
import Arrow from '../../datastore/assets/icons/blueowl-arrow.svg';
import Feathers from '../../datastore/assets/icons/blueowl-feather-pattern.svg';

const Section = styled.section`
  position: relative;
  background: ${colors.LIGHT_GRAY};
  padding: 20px 10px 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  ${media.sevens`
    padding: 100px 40px;
  `}
  ${media.tens`
    padding: 100px 80px;
  `}
`;

const FeathersWrapper = styled.div`
  display: none;
  top: -100px;
  left: 20px;
  overflow: hidden;

  position: absolute;
  ${media.sevens`
    display: block;
    max-height: 900px;
  `}
  ${media.tens`
    top: -160px;
    max-height: 950px;
  `}
`;
const FeatherWrapper = styled.div`
  height: 240px;
  svg {
    height: 100%;
    margin-top: 0;
  }
  ${media.tens`
    height: 280px;
  `}
`;

const FirstFeatherWrapper = styled(FeatherWrapper)`
  svg {
    margin-top: 0;
  }
`;
const SecondFeatherWrapper = styled(FeatherWrapper)`
  svg {
    margin-top: -50px;
  }
`;
const ThirdFeatherWrapper = styled(FeatherWrapper)`
  svg {
    margin-top: -100px;
  }
`;

const LastFeatherWrapper = styled(FeatherWrapper)`
  svg {
    margin-top: -150px;
  }
`;

const FlexCard = styled.div`
  width: unset;
  max-width: 90%;
  z-index: 1;
  background: ${colors.LIGHT_GRAY};
  ${media.sevens`
    max-width: 510px;
    width: 50%;
  `}
`;
const FlexCardInnerWrapper = styled.div`
  padding: 0;
  background: ${colors.LIGHT_GRAY};

  ${media.sevens`
    padding: 0 0 0 50px;
  `}
`;

const FlexTitle = styled(typography.h4)`
  color: ${colors.DARK_BLUE};
  margin: 30px 0 0;
  font-size: 36px;
  line-height: 36px;
  letter-spacing: -0.5px;

  ${media.sevens`
    margin: 0;
    // font-size: 36px;
    // line-height: 36px;
    // letter-spacing: -0.5px;
  `}

  ${media.tens`
    margin: 0;
    font-size: 42px;
    line-height: 49px;
    letter-spacing: -1px;
  `}
`;

export const FlexCopy = styled.div`
  ${typography.groteskRegular};
  letter-spacing: 0;
  padding: 30px 0;
  color: ${colors.DARK_BLUE};
  font-size: 21px;
  line-height: 26px;
  ${media.sevens`
    display: block;
  `}
`;

export const FlexButton = styled.a`
  ${typography.groteskMedium};
  color: ${colors.DARK_BLUE};
  display: inline-block;

  text-decoration: none;
  margin: 0 20px 0 0;
  padding: 7px 0;

  border-bottom: 2px solid ${colors.YELLOW};
  text-transform: uppercase;

  letter-spacing: 0.5px;

  font-size: 16px;
  line-height: 19px;

  span {
    margin: 0 15px 0 30px;
    transition: margin 0.3s ease-in-out;
    svg {
      path {
        stroke: ${colors.DARK_BLUE};
      }
    }
  }

  &:hover {
    color: ${colors.CONGRESS_BLUE};
    span {
      margin: 0 5px 0 40px;
      svg {
        path {
          stroke: ${colors.CONGRESS_BLUE};
        }
      }
    }
  }
`;
const ArrowWrapper = styled.span``;

const FlexImageWrapper = styled.div`
  width: 100%;
  ${media.sevens`
    width: 50%;
  `}
`;
const FlexImageInnerWrapper = styled.div`
  margin: 0;
  ${media.sevens`
    margin: 0 50px 0 0;
  `}
`;

const Image = styled(Img)`
  width: 100%;
  min-width: unset;
  ${media.sevens`
    min-width: 400px;
  `}
`;

interface FlexSectionProps {
  image: any;
  title: string;
  copy: string;
  buttons: {
    id: string;
    text: string;
    url: string;
    openInNewWindow: boolean;
  }[];
}

const FlexSection = ({
  image,
  title,
  copy,
  buttons,
}: FlexSectionProps): JSX.Element => {
  return (
    <Section>
      <FeathersWrapper>
        <FirstFeatherWrapper>
          <Feathers />
        </FirstFeatherWrapper>
        <SecondFeatherWrapper>
          <Feathers />
        </SecondFeatherWrapper>
        <ThirdFeatherWrapper>
          <Feathers />
        </ThirdFeatherWrapper>
        <LastFeatherWrapper>
          <Feathers />
        </LastFeatherWrapper>
      </FeathersWrapper>
      <FlexImageWrapper>
        <FlexImageInnerWrapper>
          <Image fluid={image.childImageSharp.fluid} />
        </FlexImageInnerWrapper>
      </FlexImageWrapper>
      <FlexCard>
        <FlexCardInnerWrapper>
          <FlexTitle dangerouslySetInnerHTML={{__html: title}} />
          <FlexCopy dangerouslySetInnerHTML={{__html: copy}} />
          <div>
            {buttons.map((button) => (
              <FlexButton
                key={`button-${button.id}`}
                href={button.url}
                target={button.openInNewWindow === true ? '_blank' : '_self'}
              >
                {button.text}
                <ArrowWrapper>
                  <Arrow />
                </ArrowWrapper>
              </FlexButton>
            ))}
          </div>
        </FlexCardInnerWrapper>
      </FlexCard>
    </Section>
  );
};

export default FlexSection;
