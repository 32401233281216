import styled from 'styled-components';
import {typography, media, colors} from '~src/theme';
import Img from 'gatsby-image';

export const Container = styled.section`
  position: relative;
  overflow: hidden;
  background-color: ${colors.DARK_BLUE};
  color: ${colors.WHITE};
`;

export const Wrapper = styled.div`
  padding: 45px 10px;

  ${media.sevens`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    padding: 60px;
    margin: 0 auto;
  `}

  ${media.tens`
    padding: 70px 70px 100px 70px;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto auto;
  `}

  ${media.twelves`
    padding: 95px 80px 125px 80px;
  `}
`;

export const ServicePackageList = styled.div`
  margin: 0;
  padding: 0;
`;

export const BlueFeathersImage = styled(Img)`
  display: none;

  ${media.sevens`
    display: block;
    position: absolute !important;
    z-index: 1;
    width: 100%;
    max-height: 1000px;
    max-width: 300px;
    bottom: 0;
  `}

  ${media.tens`
    max-height: 800px;
  `}

  ${media.twelves`
    max-height: 600px;
  `}
`;

export const ServicePackageListHeading = styled(typography.h3)`
  font-size: 21px;
  line-height: 25px;
  letter-spacing: 0.66px;
  text-align: left;
  opacity: 0.5;
  margin: 0 0 20px 0;

  ${media.sevens`
    letter-spacing: 1px;
    margin: 0 0 16px 0;
    font-size: 21px;
    line-height: 25px;
  `}
`;

export const ServicePackageListItem = styled.div`
  ${typography.groteskMedium};
  font-size: 36px;
  letter-spacing: 0.23px;
  line-height: 41px;
  text-align: left;
  cursor: pointer;
  margin: 0 0 23px 0;
  transition: all 0.3s linear;

  span {
    display: block;
    width: 100%;
    border-bottom: solid 5px transparent;
    transition: all 0.3s linear;

    #symbol {
      float: right;
      margin-left: 5px;
      width: auto;
    }

    &.active {
      border-bottom: solid 5px ${colors.YELLOW};
    }
  }

  ${media.sevens`
    font-size: 56px;
    letter-spacing: -1px;
    line-height: 65px;
    margin: 8px 0;

    span {
      width: auto;
      display: inline-block;

      #symbol {
        display: none;
      }

      &:hover {
        border-bottom: solid 5px ${colors.YELLOW};
      }

      &.active {
        border-bottom: solid 5px ${colors.YELLOW};
      }
    }
  `}
`;

export const PerkGridContainer = styled.div`
  display: none;

  ${media.sevens`
    display: grid;
    justify-content: start;
    align-content: start;
    grid-template-columns: repeat(1, auto);
    grid-template-rows: repeat(4, auto);
    grid-gap: 50px;
    margin: 0;
  `}

  ${media.tens`
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(2, auto);
    grid-row-gap: 65px;
    grid-column-gap: 100px;
  `}
`;

export const PerkSet = styled.div`
  margin-bottom: 23px;

  ${media.sevens`
    margin: 0;
  `}
`;

export const MobilePerks = styled.div`
  display: none;
  transition: all 0.3s linear;

  &.active {
    display: block;
    padding-bottom: 13px;
  }

  ${media.sevens`
    &.active {
      display: none;
    }
  `}
`;

export const PerkSetTitle = styled(typography.h4)<{showPerkSet: boolean}>`
  margin: 0 0 5px 0;
  display: ${(props: {showPerkSet: boolean}) =>
    props.showPerkSet ? 'block' : 'none'};
  font-size: 27px;
  letter-spacing: -0.61px;
  line-height: 31px;
  transition: all 0.3s linear;
  max-width: 90%;

  ${media.sevens`
    display: block;
    font-size: 33px;
    line-height: 37px;
    letter-spacing: -1px;
    opacity: ${(props: {showPerkSet: boolean}) =>
      props.showPerkSet ? '1' : '0.2'};
    max-width: 250px;
  `}

  ${media.tens`
    margin: 0 0 22px 0;
    font-size: 42px;
    line-height: 49px;
    letter-spacing: -1px;
    max-width: 350px;
  `}
`;

export const Perk = styled.div<{showPerk: boolean}>`
  ${typography.groteskMedium};

  color: ${colors.WHITE};
  font-size: 17px;
  letter-spacing: 0;
  line-height: 20px;
  padding: 5px 0;
  display: ${(props: {showPerk: boolean}) =>
    props.showPerk ? 'block' : 'none'};
  transition: all 0.3s linear;
  max-width: 90%;

  ${media.sevens`
    display: block;
    font-size: 21px;
    letter-spacing: 0;
    line-height: 25px;
    padding: 12px 0;
    opacity: ${(props: {showPerk: boolean}) => (props.showPerk ? '1' : '0.2')};
    max-width: none;
  `}
`;

export const PerkDescription = styled.div<{showPerk: boolean}>`
  ${typography.groteskRegular};

  color: ${colors.WHITE};
  opacity: 0.8;
  font-size: 16px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 19px;
  display: ${(props: {showPerk: boolean}) =>
    props.showPerk ? 'block' : 'none'};
  transition: all 0.3s linear;
  max-width: 90%;

  ${media.sevens`
    display: block;
    font-size: 21px;
    line-height: 25px;
    opacity: ${(props: {showPerk: boolean}) =>
      props.showPerk ? '0.8' : '0.4'};
    max-width: none;
  `}
`;
