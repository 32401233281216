import styled from 'styled-components';
import {media, typography, colors} from '~src/theme';
import SocialMediaWidget from '~features/SocialMediaWidget';

export const Container = styled.section`
  position: relative;
  overflow: hidden;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: 100%;
  grid-template-areas:
    'CopySection'
    'FormSection';
  width: 100%;
  height: 100%;
  padding: 60px 10px 45px 10px;

  ${media.sevens`
    padding: 60px 40px 80px 40px;
  `}

  ${media.tens`
    grid-template-columns: 60% 40%;
    grid-template-areas:
      'CopySection FormSection';
  `}

  ${media.twelves`
    padding: 95px 80px 105px 80px;
  `}
`;

export const CopySection = styled.div`
  grid-area: CopySection;
  max-width: 90%;

  ${media.tens`
    max-width: 450px;
  `}

  ${media.twelves`
    max-width: 525px;
  `}
`;

export const Title = styled(typography.h2)`
  color: ${colors.DARK_BLUE};
  font-size: 36px;
  line-height: 36px;
  letter-spacing: -0.5px;
  margin: 0 0 15px 0;

  ${media.sevens`
    margin: 0 0 15px 0;
    font-size: 80px;
    line-height: 90px;
    letter-spacing: -1px;
  `}
`;

export const Description = styled(typography.copy)`
  margin: 0 0 20px 0;
  color: ${colors.DARK_BLUE};
  line-height: 26px;

  ${media.sevens`
    margin: 0 0 32px 0;
  `}
`;

export const SocialDescription = styled(typography.copy)`
  ${typography.groteskMedium};

  margin: 0 0 32px 0;
  color: ${colors.DARK_GRAY};
`;

export const FormSection = styled.div`
  grid-area: FormSection;
  position: relative;

  ${media.sevens`
    padding-top: 25px;
  `}
`;

export const CustomSocialMediaWidget = styled(SocialMediaWidget)`
  width: 120px;
  margin: 0 0 50px 0;

  * {
    svg {
      path {
        fill: ${colors.DARK_BLUE};
      }

      &:hover {
        path {
          fill: ${colors.CONGRESS_BLUE};
        }
      }
    }
  }
`;
