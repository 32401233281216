import React from 'react';
import SocialMediaWidget from '~features/SocialMediaWidget';
import Logo from '../../datastore/assets/icons/blueowlLogo.svg';
import styled from 'styled-components';
import {media, typography, colors} from '~src/theme';

export const Footer = styled.section`
  background-color: ${colors.DARK_BLUE};
  color: ${colors.WHITE};
  ${typography.groteskMedium}
  padding: 30px 0 70px;

  ${media.sevens`
    padding: 75px 0 90px;
  `}
`;

export const PhoneNumber = styled.a`
  text-decoration: none;
  color: ${colors.WHITE};
  &:hover {
    color: ${colors.YELLOW};
  }

  font-size: 21px;
  font-weight: 500;
  letter-spacing: 0.66px;
  line-height: 26px;
`;
export const Copyright = styled.div`
  font-size: 16px;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
  color: #8b96ab;
  font-weight: 200;
`;

export const FooterItems = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px 20px 10px;
  justify-content: center;
  align-items: center;
`;
export const FooterItem = styled.div`
  display: block;
  padding: 0 20px 20px;
  text-align: center;
  width: 100%;
  ${media.sevens`
    padding: 0 20px;
    width: unset;
  `}
`;

export const LogoWrapper = styled(FooterItem)`
  padding: 0 20px;
  height: 90px;
  svg {
    height: 100%;
    border: 2px solid ${colors.DARK_BLUE};
  }
`;
interface FooterProps {
  copyrightText: string;
  phoneNumber: string;
}

const FooterSection = (props: FooterProps): JSX.Element => (
  <Footer>
    <FooterItems>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <FooterItem>
        <SocialMediaWidget {...props.socialMediaLinks} />
      </FooterItem>
      <FooterItem>
        <PhoneNumber href={`tel:${props.phoneNumber}`}>
          {props.phoneNumber}
        </PhoneNumber>
      </FooterItem>
    </FooterItems>
    <Copyright>{`©${new Date().getFullYear()} ${
      props.copyrightText
    }`}</Copyright>
  </Footer>
);
export default FooterSection;
