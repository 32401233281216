import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import {media, typography, colors} from '~src/theme';
import {TestimonialCarouselCardProps} from '@types';

export const CardContainer = styled.div`
  height: 100%;
  width: calc(100% - 15px) !important;
  min-height: 350px;
  box-shadow: 0px 8px 11px rgba(0, 0, 0, 0.235413);
  border-radius: 4.32px;
  padding: 35px 10px;
  text-align: center;
  background: ${colors.WHITE};

  ${media.fours`
    width: 45vw;
    min-width: 0;
  `}

  ${media.sevens`
    padding: 50px 30px 30px 30px;
    width: 36vw;
    min-height: 450px;
    box-shadow: 0px 12px 15px rgba(0, 0, 0, 0.24);
  `}

  ${media.tens`
    width: 26vw;
  `}

  ${media.twelves`
    width: 25vw;
  `}

  ${media.fourteens`
    width: 19vw;
  `}
`;

export const Testimonial = styled(typography.copy)`
  ${typography.groteskMedium};

  font-size: 21px;
  line-height: 25px;
  letter-spacing: -0.45px;
  text-align: center;
  color: ${colors.DARK_BLUE};
  max-width: 95%;
  margin: 20px auto;

  ${media.sevens`
    margin: 25px 0;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.55px;
    max-width: none;
  `}
`;

export const Image = styled(Img)`
  border-radius: 50%;
  height: 95px;
  width: 95px;
  margin: 0 auto;
  border: 5px solid ${colors.YELLOW};

  ${media.sevens`
    height: 115px;
    width: 115px;
  `}
`;

export const Name = styled(typography.h3)`
  text-align: center;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.4px;
  color: ${colors.DARK_BLUE};
  margin: 0;

  ${media.sevens`
    margin: 0 0 4px 0;
    font-size: 21px;
    line-height: 25px;
    letter-spacing: -0.5px;
  `}
`;

export const Title = styled(Name)`
  ${typography.groteskRegular};
  color: ${colors.DARK_GRAY};
`;

const Card = (props: TestimonialCarouselCardProps): JSX.Element => {
  const {cardImage, testimonial, name, title} = props;
  return (
    <CardContainer>
      <Image fluid={cardImage.childImageSharp.fluid} />
      <Testimonial>{testimonial}</Testimonial>
      <Name>{name}</Name>
      <Title>{title}</Title>
    </CardContainer>
  );
};

export default Card;
