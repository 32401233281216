import React from 'react';
import {
  Container,
  BackgroundImageWrapper,
  GridContainer,
  LogoPanel,
  InfoPanel,
  ImagePanel,
  ContentPanel,
  Title,
  SecondaryTitle,
  Copy,
  Image,
  PhoneNumber,
  ButtonWrapper,
  CustomSocialMediaWidget,
  ContentButtonWrapper,
  HeroFiller,
  Logo,
} from './styles';
import {HeroSectionProps, SocialMediaLinksType} from '../../types';
import {ButtonLink} from '../../theme/buttons';

interface HeaderProps extends HeroSectionProps {
  phoneNumber: string;
  socialMediaLinks: SocialMediaLinksType;
}

const Header = ({
  title,
  secondaryTitle,
  copy,
  phoneNumber,
  socialMediaLinks,
  backgroundImage,
  image,
  button,
  logo,
}: HeaderProps): JSX.Element => {
  return (
    <Container>
      <BackgroundImageWrapper fluid={backgroundImage.childImageSharp.fluid}>
        <GridContainer>
          <LogoPanel>
            <Logo fluid={logo.childImageSharp.fluid} />
          </LogoPanel>
          <InfoPanel>
            <CustomSocialMediaWidget {...socialMediaLinks} />
            <PhoneNumber href={'tel:240-672-3432'}>{phoneNumber}</PhoneNumber>
            <ButtonWrapper>
              <ButtonLink {...button} href="#contactSection" />
            </ButtonWrapper>
          </InfoPanel>
          <ImagePanel>
            <Image fluid={image.childImageSharp.fluid} />
          </ImagePanel>
          <ContentPanel>
            <Title>{title}</Title>
            <br />
            <SecondaryTitle>{secondaryTitle}</SecondaryTitle>
            <Copy>{copy}</Copy>
            <ContentButtonWrapper>
              <ButtonLink {...button} href="#contactSection" />
            </ContentButtonWrapper>
          </ContentPanel>
          <HeroFiller />
        </GridContainer>
      </BackgroundImageWrapper>
    </Container>
  );
};
export default Header;
