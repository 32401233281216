import React from 'react';
import MailchimpNetlifyForm from '~features/MailchimpNetlifyForm';
import {ContactSectionProps, SocialMediaLinksType} from '@types';
import {
  Container,
  CopySection,
  FormSection,
  Title,
  Description,
  SocialDescription,
  CustomSocialMediaWidget,
} from './styles';

interface ContactProps extends ContactSectionProps {
  socialMediaLinks: SocialMediaLinksType;
}

const ContactSection = ({
  id,
  title,
  description,
  socialDescription,
  socialMediaLinks,
}: ContactProps): JSX.Element => {
  return (
    <Container id="contactSection">
      <CopySection>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <SocialDescription>{socialDescription}</SocialDescription>
        <CustomSocialMediaWidget {...socialMediaLinks} />
      </CopySection>
      <FormSection>
        <MailchimpNetlifyForm />
      </FormSection>
    </Container>
  );
};

export default ContactSection;
