import React from 'react';
import styled from 'styled-components';
import {colors, typography} from '~src/theme';
import {isNullOrUndefined} from 'util';

enum InputType {
  Text = 'text',
  Email = 'email',
  Phone = 'phone',
  tel = 'tel',
}

interface TextInputProps {
  // Invert base colors between black and white
  invert: boolean;
  type: InputType;
  // Shared as both the id and name of the field
  name: string;
  // Aria label
  ariaLabel: string;
  // Change callback
  onChange: (event: {target: HTMLInputElement}) => void;
  required: boolean;
  placeholder: string;
  value: string;
  // Style overrides
  style: string;
  error: string;
}

const TextInput = (props: TextInputProps): JSX.Element => {
  return (
    <Input
      type={props.type}
      id={props.name}
      name={props.name}
      value={props.value}
      aria-label={props.ariaLabel}
      onChange={props.onChange}
      required={props.required}
      invert={props.invert}
      style={props.style}
      placeholder={props.placeholder}
      error={props.error}
    />
  );
};

TextInput.defaultProps = {
  required: false,
  invert: false,
  type: 'text',
};

export default TextInput;

const Input = styled.input`
  ${(p: {invert: boolean; style: string; error: string}) => `
    ${typography.groteskRegular};  

    font-size: 19px;
    line-height: 22px;
    display: block;
    background-color: ${p.invert ? colors.DARK_BLUE : colors.WHITE};
    color: ${p.invert ? colors.WHITE : colors.DARK_GRAY};
    border: 1px solid ${
      p.invert ? colors.WHITE : p.error ? colors.RED : colors.DARK_GRAY
    };
    padding: 25px;
    width: 100%;
    margin: 15px 0 0 0;
    ${p.style};
  `}
`;
