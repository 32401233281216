import React from 'react';
import {media} from '~src/theme';
import styled from 'styled-components';

const noop = () => {};

const StyledSvg = styled.svg`
  width: 21px;
  height: 41px;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100px;
  justify-content: flex-end;
  display: none;

  ${media.sevens`
    display: inline-block;
    margin-top: 15px;
  `}

  button {
    outline: 0;
    border: 0;
    cursor: pointer;
    background: inherit;
    width: 50px;

    &:active {
      transform: scale(0.9);
    }
  }
`;

const PrevGlyph = () => (
  <StyledSvg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21 41"
    fill="none"
    role="button"
  >
    <path
      d="M18 3L3 20.434L18 38"
      stroke="#002758"
      strokeWidth="4"
      strokeLinecap="square"
    />
  </StyledSvg>
);

const NextGlyph = () => (
  <StyledSvg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21 41"
    fill="none"
    role="button"
  >
    <path
      d="M3 3L18 20.434L3 38"
      stroke="#002758"
      strokeWidth="4"
      strokeLinecap="square"
    />
  </StyledSvg>
);

export default ({onPrev, onNext, className}) => (
  <StyledWrapper className={className}>
    <button
      onClick={(e) => {
        e.preventDefault();
        onPrev();
      }}
    >
      <PrevGlyph />
    </button>
    <button
      onClick={(e) => {
        e.preventDefault();
        onNext();
      }}
    >
      <NextGlyph />
    </button>
  </StyledWrapper>
);
