function encode(data: string[]): string {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

interface SubmitArgs {
  formElement: HTMLElement;
  formData: object;
  tags: string[];
  successCallback: (arg: boolean) => void;
}

export default ({
  formElement,
  formData,
  tags,
  successCallback,
}: SubmitArgs): void => {
  const bodyData = {...formData, TAGS: tags};

  // eslint-disable-next-line no-undef
  fetch('/.netlify/functions/mailchimp', {
    method: 'POST',
    body: JSON.stringify(bodyData),
  })
    .then((results) => {
      if (results.status === 200) {
        // eslint-disable-next-line no-undef
        fetch('/', {
          method: 'POST',
          headers: {'Content-Type': 'application/x-www-form-urlencoded'},
          body: encode({
            'form-name': formElement.getAttribute('name'),
            ...formData,
          }),
        })
          .then(() => {
            successCallback(true);
            return results.status;
          })
          .catch((error) => {
            // eslint-disable-next-line no-undef
            alert(error);
          });
      }
    })
    .catch((error: string) => {
      console.error(`Error Submitting to Mailchimp: ${error}`);
    });
};
