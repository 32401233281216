import {css} from 'styled-components';

export const breakpoints = {
  fours: 498,
  fives: 576,
  sixes: 676,
  sevens: 767,
  nines: 900,
  tens: 1024,
  elevens: 1140,
  twelves: 1280,
  thirteens: 1366,
  fourteens: 1440,
  sixteens: 1600,
};

export const breakpoint = {
  phone: `@media (max-width: ${breakpoints.fives}px)`, // 500px and down
  tablet: `@media (max-width: ${breakpoints.sevens}px)`, // 768px and down
  elevens: `@media (max-width: ${breakpoints.elevens}px)`, // 1000px and down
};

export const media = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (first: any, ...interpolations: any[]) => css`
    @media (min-width: ${breakpoints[label] + 1}px) {
      ${css(first, ...interpolations)}
    }
  `;

  return acc;
}, {});
