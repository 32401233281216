import React from 'react';
import styled from 'styled-components';
import {SocialMediaLinksType} from '../../types';
import FacebookIcon from '~src/datastore/assets/icons/blueowl-facebook.svg';
import InstagramIcon from '~src/datastore/assets/icons/blueowl-instagram.svg';
import LinkedInIcon from '~src/datastore/assets/icons/blueowl-linked-in.svg';
import {colors} from '../../theme';

const Container = styled.ul`
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(3, auto);
  padding: 0;
`;

const Item = styled.li`
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.2s linear;
  list-style-type: none;
  width: 35px;
  margin-left: 10px;

  a {
    display: block;
    width: 32px;
    height: 29px;
    border-radius: 50%;
  }

  svg {
    width: 100%;
    height: 100%;

    &:hover {
      path {
        fill: ${colors.YELLOW};
      }
    }
  }

  &:first-child {
    margin-left: 0;
  }

  *[fill] {
    transition: all 0.3s linear;
  }
`;

const Link = styled.a``;

const SocialMediaWidget = ({
  instagram,
  facebook,
  linkedIn,
  className,
}: SocialMediaLinksType): JSX.Element => {
  return (
    <Container className={`${className || ''}`}>
      <Item>
        <Link href={instagram} target="_blank">
          <InstagramIcon />
        </Link>
      </Item>

      <Item>
        <Link href={facebook} target="_blank">
          <FacebookIcon />
        </Link>
      </Item>

      <Item>
        <Link href={linkedIn} target="_blank">
          <LinkedInIcon />
        </Link>
      </Item>
    </Container>
  );
};

export default SocialMediaWidget;
