import styled from 'styled-components';
import {media} from '../theme';

const fonts = {
  sansSerif: 'niveau-grotesk, sans-serif',
};

const groteskRegular = `
  font-family: ${fonts.sansSerif};
  font-weight: 400;
`;

const groteskMedium = `
  font-family: ${fonts.sansSerif};
  font-weight: 500;
`;

export const copy = styled.p`
  ${groteskRegular}
  font-size: 18px;
  line-height: 23px;

  @media (min-width: 768px) {
    font-size: 21px;
    line-height: 28px;
  }
`;

export const h1 = styled.h1`
  ${groteskMedium}
  font-size: 130px;
  line-height: 96px;
  letter-spacing: normal;

  @media (min-width: 768px) {
    letter-spacing: -3px;
  }
`;

export const h2 = styled.h2`
  ${groteskMedium}
  font-size: 80px;
  line-height: 90px;
  letter-spacing: -1.5px;

  @media (min-width: 768px) {
    letter-spacing: -1px;
  }
`;

export const h3 = styled.h3`
  ${groteskMedium}
  font-size: 36px;
  line-height: 36px;
  letter-spacing: 0;

  @media (min-width: 768px) {
    font-size: 56px;
    line-height: 63px;
    letter-spacing: -1px;
  }
`;

export const h4 = styled.h4`
  ${groteskMedium}
  font-size: 42px;
  line-height: 49px;
  letter-spacing: -1px;
`;

export default {
  fonts,
  groteskRegular,
  groteskMedium,
  copy,
  h1,
  h2,
  h3,
  h4,
};
