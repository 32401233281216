import React from 'react';
import {ServiceSectionProps} from '../../types';
import {
  Container,
  Title,
  ServiceGrid,
  ServiceGridItem,
  ItemIcon,
  ItemTitle,
  IconWrapper,
} from './styles';
import Pen from '~src/datastore/assets/icons/pen.svg';
import TalkBubbles from '~src/datastore/assets/icons/talk-bubbles.svg';
import BusinessAndWardrobe from '~src/datastore/assets/icons/business-and-wardrobe.svg';
import JobSearch from '~src/datastore/assets/icons/job-search.svg';
import Eye from '~src/datastore/assets/icons/eye.svg';
import Whistle from '~src/datastore/assets/icons/whistle.svg';

const ServiceSection = ({
  heading,
  services,
}: ServiceSectionProps): JSX.Element => {
  return (
    <Container>
      <Title>{heading.headline}</Title>
      <ServiceGrid>
        <ServiceGridItem>
          <IconWrapper id={services[0].id}>
            <Pen />
          </IconWrapper>
          <ItemTitle>{services[0].title}</ItemTitle>
        </ServiceGridItem>
        <ServiceGridItem>
          <IconWrapper id={services[1].id}>
            <TalkBubbles />
          </IconWrapper>
          <ItemTitle>{services[1].title}</ItemTitle>
        </ServiceGridItem>
        <ServiceGridItem>
          <IconWrapper id={services[2].id}>
            <BusinessAndWardrobe />
          </IconWrapper>
          <ItemTitle>{services[2].title}</ItemTitle>
        </ServiceGridItem>
        <ServiceGridItem>
          <IconWrapper id={services[3].id}>
            <JobSearch />
          </IconWrapper>
          <ItemTitle>{services[3].title}</ItemTitle>
        </ServiceGridItem>
        <ServiceGridItem>
          <IconWrapper id={services[4].id}>
            <Eye />
          </IconWrapper>
          <ItemTitle>{services[4].title}</ItemTitle>
        </ServiceGridItem>
        <ServiceGridItem>
          <IconWrapper id={services[5].id}>
            <Whistle />
          </IconWrapper>
          <ItemTitle>{services[5].title}</ItemTitle>
        </ServiceGridItem>
      </ServiceGrid>
    </Container>
  );
};

export default ServiceSection;
