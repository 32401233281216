import React from 'react';
import {graphql} from 'gatsby';
import styled from 'styled-components';

import Meta from '~features/Meta';
import HeaderSection from '~features/HeaderSection';
import ServiceSection from '~features/ServiceSection';
import ServicePackageSection from '~features/ServicePackageSection';
import CarouselSection from '~features/CarouselSection';
import ContactSection from '~features/ContactSection';
import FlexSection from '~features/FlexSection';
import FooterSection from '~features/FooterSection';
import {GlobalStyles} from '~src/theme';
import {stripNodeKeys} from '../utils';

import {
  HeroSectionProps,
  SocialMediaLinksType,
  ServiceNodes,
  ServicePackageNodes,
  FlexSectionProps,
  HeadingProps,
  ImageProps,
  TestimonialCarouselNodes,
  ContactSectionProps,
} from '../types';

interface IndexPageProps {
  data: {
    site: {
      seoTitle: string;
      seoDescription: string;
      socialMediaLinks: SocialMediaLinksType;
      contactInformation: {
        phoneNumber: string;
      };
      copyrightText: string;
    };
    heroData: HeroSectionProps;
    serviceHeading: HeadingProps;
    services: {
      edges: ServiceNodes;
    };
    servicePackageHeading: HeadingProps;
    servicePackages: {
      edges: ServicePackageNodes;
    };
    servicePackageSectionImage: ImageProps;
    flexData: FlexSectionProps;
    testimonialCarouselHeading: HeadingProps;
    testimonialCarouselBackgroundImage: ImageProps;
    testimonialCarouselCards: {
      edges: TestimonialCarouselNodes;
    };
    contactData: ContactSectionProps;
  };
}

const IndexPage = (props: IndexPageProps): JSX.Element => {
  const {
    site,
    heroData,
    serviceHeading,
    services,
    servicePackageHeading,
    servicePackages,
    servicePackageSectionImage,
    flexData,
    testimonialCarouselBackgroundImage,
    testimonialCarouselCards,
    testimonialCarouselHeading,
    contactData,
  } = props.data;

  return (
    <Layout>
      <Meta title={site.seoTitle} description={site.seoDescription} />
      <GlobalStyles />
      <HeaderSection
        phoneNumber={site.contactInformation.phoneNumber}
        socialMediaLinks={site.socialMediaLinks}
        {...heroData}
      />
      <ServiceSection
        heading={serviceHeading}
        services={stripNodeKeys(services.edges)}
      />
      <ServicePackageSection
        heading={servicePackageHeading}
        servicePackages={stripNodeKeys(servicePackages.edges)}
        image={servicePackageSectionImage}
      />
      <CarouselSection
        heading={testimonialCarouselHeading}
        cards={stripNodeKeys(testimonialCarouselCards.edges)}
        backgroundImage={testimonialCarouselBackgroundImage}
      />
      <ContactSection
        socialMediaLinks={site.socialMediaLinks}
        {...contactData}
      />
      <FlexSection {...flexData} />
      <FooterSection
        phoneNumber={site.contactInformation.phoneNumber}
        copyrightText={site.copyrightText}
        socialMediaLinks={site.socialMediaLinks}
      />
    </Layout>
  );
};

export default IndexPage;

export const Layout = styled.div`
  position: relative;
`;

export const query = graphql`
  query Query {
    site: dataJson {
      seoTitle
      seoDescription
      socialMediaLinks {
        instagram
        facebook
        linkedIn
      }
      contactInformation {
        phoneNumber
      }
      copyrightText
    }
    heroData: heroJson(id: {eq: "1"}) {
      title
      secondaryTitle
      copy
      button {
        buttonText
        backgroundColor
        textColor
        openInNewWindow
      }
      backgroundImage {
        id
        childImageSharp {
          id
          fluid(maxWidth: 2880, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      image {
        id
        childImageSharp {
          id
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      logo {
        id
        childImageSharp {
          id
          fluid(maxWidth: 364, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
    serviceHeading: headingJson(id: {eq: "104"}) {
      id
      headline
    }
    services: allServiceJson {
      edges {
        node {
          id
          title
          icon {
            id
            childImageSharp {
              id
              fluid(maxWidth: 150, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
    servicePackageHeading: headingJson(id: {eq: "105"}) {
      id
      headline
    }
    servicePackages: allServicePackagesJson {
      edges {
        node {
          id
          name
          perkSets {
            id
            name
            showPerkSet
            perks {
              id
              name
              showPerk
              description
            }
          }
        }
      }
    }
    servicePackageSectionImage: file(
      relativePath: {eq: "assets/images/feathers.png"}
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 267) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    flexData: flexJson(id: {eq: "2"}) {
      title
      copy
      buttons {
        id
        url
        text
        openInNewWindow
      }
      image {
        id
        childImageSharp {
          id
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
    testimonialCarouselHeading: headingJson(id: {eq: "106"}) {
      id
      headline
    }
    testimonialCarouselCards: allTestimonialCarouselJson {
      edges {
        node {
          id
          cardImage {
            id
            childImageSharp {
              id
              fluid(maxWidth: 115, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          testimonial
          name
          title
        }
      }
    }
    testimonialCarouselBackgroundImage: file(
      relativePath: {eq: "assets/images/testimonial-carousel-background.jpg"}
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 2880) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    contactData: contactJson(id: {eq: "150"}) {
      id
      title
      description
      socialDescription
    }
  }
`;
